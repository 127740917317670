import { ReplaySubject } from 'rxjs';

type ValuesOf<T> = T[keyof T];

export type ABTestTaskListType = keyof typeof abTestList;
export type HeaderMainGroupType = ValuesOf<{
  [K in keyof typeof abTestList]: typeof abTestList[K]['headerMainGroup'];
}>;
export type ABVariantsType = ValuesOf<{
  [K in keyof typeof abTestList]: ValuesOf<typeof abTestList[K]['abTestGroupIds']>;
}>;

export interface ABTestItem<K extends keyof typeof abTestList> {
  nameOfTask?: ABTestTaskListType;
  alias?: string;
  headerMainGroup: HeaderMainGroupType;
  abTestVariants: ABVariantsType[];
  abTestGroupIds: Record<keyof typeof abTestList[K]['abTestGroupIds'], ABVariantsType>;
  resolvedValue?: ABVariantsType;
  resolvedValue$?: ReplaySubject<ABVariantsType>;
  resolvedCookieValue?: ABVariantsType;
  syncOnAuth?: boolean;
  debug?: boolean;
};

export const AB_TEST_LIST: {
  [K in keyof typeof abTestList]: ABTestItem<K>;
} = {} as any;

export const abTestList = {
  'DEVSP522': {
    alias: 'DEVSP522',
    headerMainGroup: 'ID291',
    abTestGroupIds: {
      V1: 'ID291',
      V2: 'ID292',
    },
    syncOnAuth: true,
    debug: false,
  },
} as const;
